import {
  RecommendationTaskType,
  TiktokRecommendationKey,
} from '$extensionSrc/utils/enums'

import metadata from '../metadata/tiktok.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import spec from '../specs/tiktok.yaml'

const TIKTOK_RECOMMENDATION_KEYS = [
  TiktokRecommendationKey.TIKTOK_2FA_REMOVE_SAVED_LOGIN,
  TiktokRecommendationKey.TIKTOK_ACTIVITY_STATUS,
  TiktokRecommendationKey.TIKTOK_AD_DATA,
  TiktokRecommendationKey.TIKTOK_COMMENTS,
  TiktokRecommendationKey.TIKTOK_CONTENT_INTERACTIONS,
  TiktokRecommendationKey.TIKTOK_DISCOVERABILITY,
  TiktokRecommendationKey.TIKTOK_FAMILY_PAIRING,
  TiktokRecommendationKey.TIKTOK_LIKED_VIDEOS,
  TiktokRecommendationKey.TIKTOK_MANAGE_ADS,
  TiktokRecommendationKey.TIKTOK_MESSAGES,
  TiktokRecommendationKey.TIKTOK_SCREEN_TIME,
  TiktokRecommendationKey.TIKTOK_SUGGEST_ACCOUNT_TO_SYNCED_CONTACTS,
]

const TIKTOK_RECOMMENDATIONS_REGISTRY: Record<
  TiktokRecommendationKey,
  Recommendation
> = {
  [TiktokRecommendationKey.TIKTOK_2FA_REMOVE_SAVED_LOGIN]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_2FA_REMOVE_SAVED_LOGIN]),
    key: TiktokRecommendationKey.TIKTOK_2FA_REMOVE_SAVED_LOGIN,
  },
  [TiktokRecommendationKey.TIKTOK_ACTIVITY_STATUS]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_ACTIVITY_STATUS]),
    key: TiktokRecommendationKey.TIKTOK_ACTIVITY_STATUS,
  },
  [TiktokRecommendationKey.TIKTOK_AD_DATA]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_AD_DATA]),
    key: TiktokRecommendationKey.TIKTOK_AD_DATA,
  },
  [TiktokRecommendationKey.TIKTOK_COMMENTS]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_COMMENTS]),
    key: TiktokRecommendationKey.TIKTOK_COMMENTS,
  },
  [TiktokRecommendationKey.TIKTOK_CONTENT_INTERACTIONS]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_CONTENT_INTERACTIONS]),
    key: TiktokRecommendationKey.TIKTOK_CONTENT_INTERACTIONS,
  },
  [TiktokRecommendationKey.TIKTOK_DISCOVERABILITY]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_DISCOVERABILITY]),
    key: TiktokRecommendationKey.TIKTOK_DISCOVERABILITY,
  },
  [TiktokRecommendationKey.TIKTOK_FAMILY_PAIRING]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_FAMILY_PAIRING]),
    key: TiktokRecommendationKey.TIKTOK_FAMILY_PAIRING,
  },
  [TiktokRecommendationKey.TIKTOK_LIKED_VIDEOS]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_LIKED_VIDEOS]),
    key: TiktokRecommendationKey.TIKTOK_LIKED_VIDEOS,
  },
  [TiktokRecommendationKey.TIKTOK_MANAGE_ADS]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_MANAGE_ADS]),
    key: TiktokRecommendationKey.TIKTOK_MANAGE_ADS,
  },
  [TiktokRecommendationKey.TIKTOK_MESSAGES]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_MESSAGES]),
    key: TiktokRecommendationKey.TIKTOK_MESSAGES,
  },
  [TiktokRecommendationKey.TIKTOK_SCREEN_TIME]: {
    ...parseSpec(spec[TiktokRecommendationKey.TIKTOK_SCREEN_TIME]),
    key: TiktokRecommendationKey.TIKTOK_SCREEN_TIME,
  },
  [TiktokRecommendationKey.TIKTOK_SUGGEST_ACCOUNT_TO_SYNCED_CONTACTS]: {
    ...parseSpec(
      spec[TiktokRecommendationKey.TIKTOK_SUGGEST_ACCOUNT_TO_SYNCED_CONTACTS],
    ),
    key: TiktokRecommendationKey.TIKTOK_SUGGEST_ACCOUNT_TO_SYNCED_CONTACTS,
  },
}

export const tiktokRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => TIKTOK_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () => TIKTOK_RECOMMENDATION_KEYS,
  getContentTaskKeys: () =>
    TIKTOK_RECOMMENDATION_KEYS.filter(
      (key) =>
        TIKTOK_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.CONTENT,
    ),
  getExperienceTaskKeys: () =>
    TIKTOK_RECOMMENDATION_KEYS.filter(
      (key) =>
        TIKTOK_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.EXPERIENCE,
    ),
  getRecommendationsRegistry: () => TIKTOK_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
