import { useMemo } from 'react'

import { PLATFORM } from '../../utils/enums'

export function getPlatformName(
  platform: PLATFORM | null | undefined,
): string | null | undefined {
  if (platform === undefined) {
    return undefined
  }
  switch (platform) {
    case PLATFORM.FACEBOOK:
      return 'Facebook'
    case PLATFORM.GOOGLE:
      return 'Google'
    case PLATFORM.INSTAGRAM:
      return 'Instagram'
    case PLATFORM.LINKEDIN:
      return 'LinkedIn'
    case PLATFORM.REDDIT:
      return 'Reddit'
    case PLATFORM.STRAVA:
      return 'Strava'
    case PLATFORM.TIKTOK:
      return 'TikTok'
    case PLATFORM.TWITTER:
      return 'X (Twitter)'
    case PLATFORM.VENMO:
      return 'Venmo'
    case PLATFORM.YOUTUBE:
      return 'YouTube'
    default:
      return null
  }
}

export function usePlatformName(
  platform: PLATFORM | null | undefined,
): string | null | undefined {
  return useMemo(() => getPlatformName(platform), [platform])
}
