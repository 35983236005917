import UAParser from 'ua-parser-js'

import BuildConfig from '$extensionSrc/buildConfig'
import { graphqlUnauthPath, getApiUrl } from '$extensionSrc/consts/urlConsts'
import { getSpeed } from '$extensionSrc/data/speed'
import { fetchWithAuth } from '$extensionSrc/serverClient'
import * as canIUse from '$extensionSrc/utils/canIUse'
import { getUserAccountId } from '$extensionSrc/utils/getUserAccountData'

import { getOrSetDoNotTrack } from './dnt'
import {
  getExtensionInstallationId,
  getAnonymousUserId,
  setAnonymousUserId,
} from './idGetSet'
import {
  AppName,
  Context,
  Environment,
  Event,
  Interaction,
  TrackInput,
  TrackResponse,
} from './types'

async function getGlobalContext(): Promise<Context> {
  // Returns global context for all events
  let context: Context = {
    appName: BuildConfig.APP_NAME as AppName,
    environment: BuildConfig.REACT_APP_ENV as Environment,
    timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || undefined,
    userAccountId: await getUserAccountId(),
  }
  if (typeof window !== 'undefined') {
    let url = window.location.href

    // Only keep the origin of window.location.href to guard against
    // accidentally logging user profiles (e.g. facebook.com/triketora)
    // and thus exposing who is using the product.
    if (url) {
      try {
        const urlObject = new URL(url)

        if (
          urlObject.protocol !== 'chrome-extension:' &&
          !urlObject.hostname.endsWith('.blockpartyapp.com') &&
          urlObject.hostname !== '127.0.0.1'
        ) {
          url = urlObject.origin
        }
      } catch {
        // pass
      }
    }

    context = {
      ...context,
      screenHeight: window?.screen?.height,
      screenWidth: window?.screen?.width,
      url,
    }
  }

  if (navigator?.userAgent) {
    const result = UAParser(navigator.userAgent)
    context = {
      ...context,
      // These will all be empty strings on 'result' if their values could not
      // be determined
      browserName: result.browser.name || undefined,
      browserVersion: result.browser.version || undefined,
      deviceBrand: result.device.vendor || undefined,
      deviceFamily: result.device.type || undefined,
      deviceModel: result.device.model || undefined,
      osName: result.os.name || undefined,
      osVersion: result.os.version || undefined,
      userAgent: navigator.userAgent,
    }
  }

  if (canIUse.chromeRuntime()) {
    context = {
      ...context,
      installationId: await getExtensionInstallationId(),
      version: chrome.runtime.getManifest().version,
    }
  }

  if (canIUse.chromeStorage()) {
    const speed = await getSpeed()
    if (speed?.network) {
      context = {
        ...context,
        network: speed.network,
      }
    }
    if (speed?.pageLoad) {
      context = {
        ...context,
        pageLoad: speed.pageLoad,
      }
    }
  }

  return context
}

export async function track(event: Event | Interaction): Promise<void> {
  // Early return if we're in Storybook, because we can't use localStorage
  // there
  if ((typeof chrome !== 'undefined' && (chrome.storage as any))?.isMocked) {
    return
  }
  const doNotTrack = await getOrSetDoNotTrack()
  if (
    doNotTrack &&
    event.name !== 'extension:optInMetrics' &&
    event.name !== 'extension:optOutMetrics'
  ) {
    return
  }

  const globalContext = await getGlobalContext()

  const context: Context = {
    ...globalContext,
    // Event's context supersedes global context
    // TODO: Remove this 'typeof' once we have an event that specifies its own
    // context -- at that point the typing will be okay
    ...('context' in event && typeof event.context === 'object'
      ? event.context
      : {}),
  }
  const timestamp = new Date().toISOString()
  const url = getApiUrl(graphqlUnauthPath)

  const query = `
    mutation Track($trackInput: TrackInput!) {
      track(trackInput: $trackInput)
    }
  `
  const trackInput: TrackInput = {
    // If this is undefined, a new anonymous user ID will be generated or
    // retrieved from the anonymous_user_id cookie set on extension server
    anonymousUserId: await getAnonymousUserId(),
    context: JSON.stringify(context),
    name: event.name,
    properties:
      'properties' in event ? JSON.stringify(event.properties) : undefined,
    timestamp,
  }
  const variables = { trackInput }

  const requestInit: RequestInit = {
    body: JSON.stringify({ operationName: 'Track', query, variables }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }

  // Don't send XHR requests in Storybook or in automated tests unless we
  // specifically mock BuildConfig.REACT_APP_ENV
  if (!BuildConfig.REACT_APP_ENV) {
    return
  }

  // Log events to the console in development mode. We're not using _debug here
  // because
  // - it makes Webflow's bundle balloon from 30kb to 103kb, and
  // - makes us use the svgr esbuild plugin due to a dependency on
  // platformMetadataUtils.
  if (BuildConfig.REACT_APP_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(`%ctrack`, 'background: #a6a6a6; color: #ffffff', {
      // This is the same as trackInput above, but without the JSON
      // stringification. We'll put name, properties, and context first for
      // easy reading the console.
      name: event.name,
      properties: 'properties' in event ? event.properties : undefined,
      context,
      anonymousUserId: trackInput.anonymousUserId,
      timestamp,
    })
  }

  const response = await fetchWithAuth(url, requestInit)

  if (response.ok) {
    let data: TrackResponse | undefined
    try {
      data = await response.json()
    } catch {
      return
    }
    if (data?.data?.track) {
      // The string value returned from the track mutation is the anonymous
      // user ID according to the server. Set this value as a cookie on
      // https://www.blockpartyapp.com or within chrome.storage to get around
      // third-party cookie restrictions.
      await setAnonymousUserId(data.data.track)
    }
  }
}
