import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'

import clsx from 'clsx'

import { TaskButton } from '$extensionSrc/components/Buttons'
import ProtipBanner from '$extensionSrc/components/Sidebar/RecommendationCards/RecommendationCardContainer/RecommendationCard/ProtipBanner'
import { AccessLevel } from '$extensionSrc/data/user/accessByPlatform'
import GrayBars from '$extensionSrc/icons/gray-bars.svg'
import fbPhotoUntagging from '$extensionSrc/images/fb-photo-untagging.png'
import * as communication from '$extensionSrc/utils/communication'
import {
  FullScreenModalType,
  RecommendationState,
  STORAGE_KEY,
} from '$extensionSrc/utils/enums'
import * as localStorage from '$extensionSrc/utils/localStorage'

import BatchRow from './BatchRow'

import './FbPhotoUntaggingBody.scss'

export default function FbPhotoUntaggingBody({
  accessLevel,
  animateFirstRenderForUpdatedCard,
  isAnimatingCardStateTransition,
  customActionDescription,
  defaultBodyTitle,
  initialState,
  languageLocked,
  recommendationState,
  lastGatheredTimestamp,
  platform,
  platformUserId,
  recommendationKey,
  onClickCheckSettings,
  onClickUpsell,
  locked = false,
}) {
  const [reloading, setReloading] = React.useState(false)

  React.useEffect(() => {
    if (lastGatheredTimestamp && Date.now() - lastGatheredTimestamp < 1000) {
      setReloading(true)
      setTimeout(() => {
        setReloading(false)
      }, 500)
    }
  }, [lastGatheredTimestamp])

  const onClickOpenModal = (
    photoDetails = null,
    photoRangeTitle = null,
    previousUpdates = 0,
  ) => {
    communication.sendSetActiveTabForPlatform(platform).then(() => {
      const activeTabStorageKey =
        communication.getActiveTabStorageKeyForPlatform(platform)
      localStorage.getSingle(activeTabStorageKey).then((activeTabId) =>
        localStorage.set({
          [STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL]: {
            [activeTabId]: {
              selectedModalType:
                FullScreenModalType.FACEBOOK_PHOTO_UNTAGGING_MODAL,
              selectedModalData: {
                photoDetails,
                platform,
                platformUserId,
                previouslyUpdated: previousUpdates,
                recommendationKey,
                title: photoRangeTitle,
              },
            },
          },
        }),
      )
    })
  }

  const handleTaskButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      if (accessLevel === AccessLevel.PREVIEW) {
        onClickUpsell()
      } else {
        onClickCheckSettings()
      }
    }, [accessLevel, onClickUpsell, onClickCheckSettings])

  const hasLoadedAllPhotos = initialState?.hasLoadedAllPhotos
  const photosAlreadyUntagged = initialState?.isPhotoUntagged || {}

  const untaggedPhotoCount = Object.values(photosAlreadyUntagged).filter(
    (x) => x,
  ).length

  let batchTable
  let totalPhotoCount = 0
  if (initialState !== null && Object.keys(initialState).includes('batches')) {
    const batches = initialState.batches

    const batchRows: ReactNode[] = []
    let startIndex = 1

    Object.keys(batches).forEach((batchId) => {
      const originalBatchLength = batches[batchId].length
      totalPhotoCount += originalBatchLength

      let filteredBatch = []
      if (
        photosAlreadyUntagged !== null &&
        photosAlreadyUntagged !== undefined
      ) {
        filteredBatch = batches[batchId].filter(
          (photoDetails) =>
            !Object.keys(photosAlreadyUntagged).includes(photoDetails.photoId),
        )
      } else {
        filteredBatch = batches[batchId]
      }

      const batchRowText =
        originalBatchLength === 1
          ? `Photo ${startIndex}`
          : `Photos ${startIndex}-${startIndex + originalBatchLength - 1}`

      batchRows.push(
        <BatchRow
          key={batchId}
          isUpdated={filteredBatch.length < originalBatchLength}
          text={batchRowText}
          photoDetails={filteredBatch}
          previouslyUpdated={originalBatchLength - filteredBatch.length}
          onClick={onClickOpenModal}
        />,
      )

      startIndex += originalBatchLength
    })

    batchTable = <div className="batch-rows">{batchRows}</div>
  }

  const protipText = (
    <span>
      When you have many photos, reviewing just <b>100 photos a day</b> can make
      it manageable.
    </span>
  )

  let bodyTitle
  let protip
  if (recommendationState === RecommendationState.INCOMPLETE) {
    bodyTitle = defaultBodyTitle
    protip = (
      <ProtipBanner iconType="calendar" variant="blue">
        {protipText}
      </ProtipBanner>
    )
  } else if (recommendationState === RecommendationState.FULFILLED) {
    bodyTitle = <>All recommendations already complete &#x1F389;</>
  }

  const footerDescriptionClassNames = [
    'footer-description',
    'body3',
    'darkest-gray',
  ]
  let footerDescription
  let footerButtons
  if (
    recommendationState === RecommendationState.INCOMPLETE &&
    untaggedPhotoCount === 0
  ) {
    footerDescription = customActionDescription
    footerButtons = (
      <TaskButton
        buttonText={totalPhotoCount === 0 ? 'Scan photos' : 'Load more photos'}
        disabled={locked}
        onClick={handleTaskButtonClick}
        preview={accessLevel === AccessLevel.PREVIEW}
      />
    )
  } else if (
    recommendationState === RecommendationState.INCOMPLETE &&
    untaggedPhotoCount > 0
  ) {
    footerDescription = `You removed ${untaggedPhotoCount} ${
      untaggedPhotoCount === 1 ? 'tag' : 'tags'
    }`
    footerButtons = (
      <TaskButton
        buttonText={totalPhotoCount === 0 ? 'Scan photos' : 'Load more photos'}
        disabled={locked}
        onClick={handleTaskButtonClick}
        preview={accessLevel === AccessLevel.PREVIEW}
      />
    )
  } else if (recommendationState === RecommendationState.FULFILLED) {
    footerDescriptionClassNames.push('space-between', 'no-buttons-underneath')
    footerDescription = <>No action required! You're already good to go 🎉</>
  } else {
    footerDescriptionClassNames.push('space-between', 'no-buttons-underneath')
    footerDescription = (
      <>
        You removed {totalPhotoCount} {totalPhotoCount === 1 ? 'tag' : 'tags'}{' '}
        🎉
      </>
    )
  }

  const notStartedYet = useMemo(
    () =>
      recommendationState === RecommendationState.INCOMPLETE &&
      untaggedPhotoCount === 0 &&
      totalPhotoCount === 0,
    [recommendationState, totalPhotoCount, untaggedPhotoCount],
  )

  let content = (
    <>
      {recommendationState === RecommendationState.INCOMPLETE && (
        <div className="recommendation-body">
          {protip}
          {bodyTitle && (
            <div className="recommendation-body-title">
              <div className="body1 black bold">{bodyTitle}</div>
            </div>
          )}
          {notStartedYet && (
            <div className="not-started-yet">
              <img alt="" src={fbPhotoUntagging} />
              <div className="body3 instructions">
                To start untagging yourself in bulk, first{' '}
                <strong>Scan Photos</strong> <span className="arrow">↓</span>
              </div>
            </div>
          )}
          {batchTable}
          {!hasLoadedAllPhotos && (
            <div className="body3 darker-gray photo-count">
              {totalPhotoCount > 0 &&
                `Showing ${totalPhotoCount === 1 ? `1` : `1-${totalPhotoCount}`} of many`}
            </div>
          )}
        </div>
      )}
      <div className="recommendation-footer">
        {footerDescription && (
          <div className={clsx(footerDescriptionClassNames)}>
            {footerDescription}
          </div>
        )}
        {footerButtons}
        {languageLocked && (
          <span aria-hidden className="tooltip-text body3">
            To use this feature, switch your language settings on Facebook to US
            English
          </span>
        )}
      </div>
    </>
  )

  if (reloading) {
    content = (
      <div className="recommendation-body">
        <div className="check-settings-illustration">
          <GrayBars aria-hidden className="gray-bars" />
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'FbPhotoUntaggingBody',
        isAnimatingCardStateTransition ||
          (animateFirstRenderForUpdatedCard && 'fade'),
      )}
    >
      {content}
    </div>
  )
}
