import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import clsx from 'clsx'
import CopyToClipboard from 'react-copy-to-clipboard'
// @TODO: Delete after scrollToAnchor is moved into ScrollingContext
import { scroller } from 'react-scroll'

import { startAutomationMachineForRecommendation } from '$extensionSrc/automations/automationStageMachine'
import { InstagramAccountVisibility } from '$extensionSrc/automations/instagram/accountEnums'
import { RecommendationAutomationDef } from '$extensionSrc/automations/interfaces'
import ImpressionLogger from '$extensionSrc/components/ImpressionLogger'
import InteractiveElement from '$extensionSrc/components/InteractiveElement'
import LogContextProvider from '$extensionSrc/components/LogContextProvider'
import Toast from '$extensionSrc/components/Toast'
import { updatePlatformUserAccountData } from '$extensionSrc/data/platform/platformUserAccountData'
import { AccessLevel } from '$extensionSrc/data/user/accessByPlatform'
import LinkIcon from '$extensionSrc/icons/chain-link-icon.png'
import Confetti from '$extensionSrc/icons/confetti.svg'
import SectionCollapseIcon from '$extensionSrc/icons/section-collapse-icon.svg'
import SectionExpandIcon from '$extensionSrc/icons/section-expand-icon.svg'
import RecommendationDisplayWrapper from '$extensionSrc/recommendations/recommendationDisplayWrapper'
import { RecommendationCardDisplayData } from '$extensionSrc/recommendations/recommendationProgressTypes'
import { RecommendationKeyEnum } from '$extensionSrc/recommendations/recommendationTypes'
import * as state from '$extensionSrc/state'
import * as communication from '$extensionSrc/utils/communication'
import { getTimeSinceInSeconds } from '$extensionSrc/utils/dateUtils'
import {
  AutomationType,
  FBRecommendationKey,
  PLATFORM,
  RecommendationCardType,
  RecommendationKey,
  RecommendationState,
  TwitterRecommendationKey,
} from '$extensionSrc/utils/enums'

import AutomatedBody from './AutomatedBody'
import Banner from './Banner'
import FbPhotoUntaggingBody from './FbPhotoUntaggingBody'
import FbUnfriendBody from './FbUnfriendBody'
import Header from './Header'
import InstagramAccountTypeSubheader from './InstagramAccountTypeSubheader'
import ManualBody from './ManualBody'
import TaskBody from './TaskBody'

import './RecommendationCard.scss'

/**
 * Inform a user of the details of a recommendation, the current state of that
 * recommendation, and allow them to take action on it.
 */
export default function RecommendationCard({
  accessLevel,
  automationDef,
  displayPosition, // only used for metrics
  isLanguageSupported,
  isLessRelevant, // determined by personalization
  lastDeeplinkRecommendationKey,
  onAnimatedTransition,
  onAutomationStarted,
  platform,
  progress,
  recommendationKey,
  showUpsellModal,
  spec,
  userId: platformUserId,
}: {
  accessLevel: AccessLevel
  automationDef: RecommendationAutomationDef
  displayPosition: number
  isLanguageSupported: boolean
  isLessRelevant: boolean
  isPlaybookCompleted: boolean
  lastDeeplinkRecommendationKey: string | null
  onAnimatedTransition: (recommendationKey: RecommendationKey) => void
  onAutomationStarted: (recommendationKey: RecommendationKey) => void
  platform: string
  progress: RecommendationCardDisplayData
  recommendationKey: string
  showUpsellModal: () => void
  spec: RecommendationDisplayWrapper
  userId: any
}) {
  const {
    bodyTitle,
    canUndo,
    concernTags,
    confirmTaskButtonText,
    continuingTaskButtonText,
    customActionDescription,
    explanationText,
    hasAutomatedGather,
    isTask,
    isPhoto,
    recommendationCardType,
    recommendationTitle,
    deeplinkShareUrl,
    settings,
    steps,
    taskButtonText,
    taskContext,
    taskTitle,
    taskUpdatedText,
    taskWarningText,
  } = spec
  const {
    automationErrors,
    initialState,
    recommendationState,
    lastGatheredTimestamp,
    lastUpdatedTimestamp,
    settingsSelected,
  } = progress

  const [showExplanation, setShowExplanation] = useState(
    recommendationState === RecommendationState.INCOMPLETE || isTask,
  )

  const [isAnimatingCardStateTransition, setIsAnimatingCardStateTransition] =
    useState(false)
  const [
    animateFirstRenderForUpdatedCard,
    setAnimateFirstRenderForUpdatedCard,
  ] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)

  // @TODO: Move this into ScrollingContext
  const scrollToAnchor = useCallback((anchorId: string, smooth: boolean) => {
    scroller.scrollTo(anchorId, {
      smooth,
      duration: 250,
    })
  }, [])

  const toggleShowExplanation = () =>
    setShowExplanation((prevValue) => !prevValue)

  const animateTransition = (callback) => {
    setIsAnimatingCardStateTransition(true)
    setTimeout(() => {
      callback()
      setIsAnimatingCardStateTransition(false)
    }, 500)
    onAnimatedTransition(recommendationKey as RecommendationKey)
  }

  const isPostDeeplinkGather =
    recommendationKey === lastDeeplinkRecommendationKey &&
    lastGatheredTimestamp &&
    getTimeSinceInSeconds(lastGatheredTimestamp) <= 2

  const [showGlow, setShowGlow] = useState(false)

  useEffect(() => {
    if (isPostDeeplinkGather) {
      // @TODO: Move this into ScrollingContext
      scrollToAnchor(recommendationKey, true)
      if (recommendationState === RecommendationState.INCOMPLETE) {
        setShowGlow(true)
        setTimeout(() => {
          setShowGlow(false)
        }, 5000)
      } else if (recommendationState === RecommendationState.FULFILLED) {
        setShowConfetti(true)
        setTimeout(() => {
          setShowConfetti(false)
        }, 1500)
      }
    }
  }, [
    isPostDeeplinkGather,
    recommendationKey,
    recommendationState,
    scrollToAnchor,
    setShowGlow,
  ])

  // When the completion state updates, trigger a toggle of show explanation
  useEffect(() => {
    setShowExplanation(
      recommendationState === RecommendationState.INCOMPLETE || isTask,
    )
    if (
      recommendationState === RecommendationState.UPDATED &&
      lastUpdatedTimestamp &&
      getTimeSinceInSeconds(lastUpdatedTimestamp) <= 2
    ) {
      if (recommendationCardType !== RecommendationCardType.MANUAL) {
        setAnimateFirstRenderForUpdatedCard(true)
        setTimeout(() => setAnimateFirstRenderForUpdatedCard(false), 500)
      }
      // Don't show confetti for Facebook unfriend or Twitter delete your posts
      if (
        recommendationKey !== FBRecommendationKey.FB_UNFRIEND &&
        recommendationKey !==
          TwitterRecommendationKey.TWITTER_DELETE_POSTS_BEFORE
      ) {
        setShowConfetti(true)
        setTimeout(() => {
          setShowConfetti(false)
        }, 1500)
      }
    }
  }, [
    recommendationState,
    scrollToAnchor,
    recommendationKey,
    setAnimateFirstRenderForUpdatedCard,
    recommendationCardType,
    lastUpdatedTimestamp,
    isTask,
  ])

  const setUpdateAutomation = useCallback(() => {
    startAutomationMachineForRecommendation(
      platform,
      platformUserId,
      recommendationKey,
      AutomationType.UPDATE,
      null,
      recommendationKey === lastDeeplinkRecommendationKey,
    )
  }, [
    lastDeeplinkRecommendationKey,
    platform,
    platformUserId,
    recommendationKey,
  ])

  const setUndoAutomation = () => {
    startAutomationMachineForRecommendation(
      platform,
      platformUserId,
      recommendationKey,
      AutomationType.UNDO,
      null,
    )
  }

  // On click handlers
  const onClickUpdate = useCallback(async () => {
    onAutomationStarted(recommendationKey as RecommendationKey)
    await communication.sendSetActiveTabForPlatform(platform)
    setUpdateAutomation()
  }, [onAutomationStarted, platform, recommendationKey, setUpdateAutomation])

  const onClickMarkAsDone = () => {
    animateTransition(() => {
      updatePlatformUserAccountData(platform as PLATFORM, platformUserId, {})
      state.setRecommendationStatus(
        platform,
        platformUserId,
        recommendationKey,
        {
          updated: true,
        },
      )
      setShowExplanation(false)
    })
  }

  const onClickSkip = () => {
    animateTransition(() => {
      updatePlatformUserAccountData(platform as PLATFORM, platformUserId, {})
      state.setRecommendationStatus(
        platform,
        platformUserId,
        recommendationKey,
        {
          skipped: true,
        },
      )
      setShowExplanation(false)
    })
  }

  const onClickUndoAutomated = async () => {
    onAutomationStarted(recommendationKey as RecommendationKey)
    await communication.sendSetActiveTabForPlatform(platform)
    setUndoAutomation()
  }

  const onClickUndoManual = () => {
    animateTransition(() => {
      state.setRecommendationStatus(
        platform,
        platformUserId,
        recommendationKey,
        {
          undoUpdated: true,
        },
      )
      setShowExplanation(true)
    })
  }

  const onClickUndoMarkAsSkipped = () => {
    animateTransition(() => {
      state.setRecommendationStatus(
        platform,
        platformUserId,
        recommendationKey,
        {
          undoSkipped: true,
        },
      )
      setShowExplanation(true)
    })
  }

  const onClickCheckSettings = useCallback(async () => {
    const setGatherAutomation = () => {
      startAutomationMachineForRecommendation(
        platform,
        platformUserId,
        recommendationKey,
        AutomationType.GATHER,
        null,
      )
    }

    onAutomationStarted(recommendationKey as RecommendationKey)
    await communication.sendSetActiveTabForPlatform(platform)
    setGatherAutomation()
  }, [onAutomationStarted, platform, platformUserId, recommendationKey])

  const onClickUpsell = useCallback(() => {
    showUpsellModal()
  }, [showUpsellModal])

  const handleTaskConfirmClick = useCallback(async () => {
    await communication.sendSetActiveTabForPlatform(platform)
    // Experiment state management and flows may break our normal assumptions.
    // Ensure progress for this recommendation is initialized before update.
    await state.updateRecommendation(
      platform,
      platformUserId,
      recommendationKey as RecommendationKeyEnum,
      {},
      { doNotUpdateLastUpdatedTimestamp: true },
    )
    onClickUpdate()
  }, [platform, platformUserId, recommendationKey, onClickUpdate])

  let subheader
  if (platform === PLATFORM.INSTAGRAM) {
    // If this recommendation is only for public accounts
    if (spec.accountTypes?.length === 1 && spec.accountTypes[0] === 'Public') {
      subheader = (
        <InstagramAccountTypeSubheader
          type={InstagramAccountVisibility.PUBLIC}
        />
      )
    }
  }

  let addlExplanationTextClassName
  if (showExplanation === null) {
    addlExplanationTextClassName = 'initial'
  } else if (showExplanation === false) {
    addlExplanationTextClassName = 'hide'
  } else {
    addlExplanationTextClassName = 'show'
  }

  const addClickable = explanationText ? '' : 'not-clickable'

  let content: ReactNode | undefined
  switch (recommendationCardType) {
    case RecommendationCardType.AUTOMATED:
      content = (
        <AutomatedBody
          accessLevel={accessLevel}
          animateFirstRenderForUpdatedCard={animateFirstRenderForUpdatedCard}
          isAnimatingCardStateTransition={isAnimatingCardStateTransition}
          automationDef={automationDef}
          automationErrors={automationErrors}
          canUndo={canUndo}
          customActionDescription={customActionDescription}
          defaultBodyTitle={bodyTitle}
          initialState={initialState}
          isLanguageSupported={isLanguageSupported}
          isLessRelevant={isLessRelevant}
          isPhoto={isPhoto}
          lastGatheredTimestamp={lastGatheredTimestamp}
          lastUpdatedTimestamp={lastUpdatedTimestamp}
          onClickAction={onClickUpdate}
          onClickMarkAsDone={onClickMarkAsDone}
          onClickScanSettings={onClickCheckSettings}
          onClickSkip={onClickSkip}
          onClickUndo={onClickUndoAutomated}
          onClickUndoMarkAsDone={onClickUndoManual}
          onClickUndoSkip={onClickUndoMarkAsSkipped}
          onClickUpsell={onClickUpsell}
          platform={platform}
          recommendationKey={recommendationKey}
          recommendationState={recommendationState}
          settings={settings}
          settingsSelected={settingsSelected}
          truncate={isPostDeeplinkGather || false}
          userId={platformUserId}
          locked={!isLanguageSupported}
          showGlow={showGlow}
        />
      )
      break
    case RecommendationCardType.FACEBOOK_PHOTO_UNTAGGING:
      content = (
        <FbPhotoUntaggingBody
          accessLevel={accessLevel}
          animateFirstRenderForUpdatedCard={animateFirstRenderForUpdatedCard}
          isAnimatingCardStateTransition={isAnimatingCardStateTransition}
          customActionDescription={customActionDescription}
          defaultBodyTitle={bodyTitle}
          initialState={initialState}
          languageLocked={!isLanguageSupported}
          recommendationState={recommendationState}
          lastGatheredTimestamp={lastGatheredTimestamp}
          platform={platform}
          platformUserId={platformUserId}
          recommendationKey={recommendationKey}
          onClickCheckSettings={onClickCheckSettings}
          onClickUpsell={onClickUpsell}
          locked={!isLanguageSupported}
        />
      )
      break
    case RecommendationCardType.TASK:
      if (recommendationKey !== FBRecommendationKey.FB_UNFRIEND) {
        content = (
          <TaskBody
            accessLevel={accessLevel}
            automationErrors={automationErrors}
            confirmTaskButtonText={confirmTaskButtonText}
            continuingTaskButtonText={continuingTaskButtonText}
            initialState={initialState}
            lastUpdatedTimestamp={lastUpdatedTimestamp}
            locked={false}
            onConfirmClick={handleTaskConfirmClick}
            onClickUpsell={onClickUpsell}
            platformUserId={platformUserId}
            recommendationKey={recommendationKey}
            recommendationState={recommendationState}
            settingsSelected={settingsSelected}
            statusText={automationErrors?.message || initialState?.message}
            taskButtonText={taskButtonText}
            taskContext={taskContext}
            taskTitle={taskTitle}
            taskUpdatedText={taskUpdatedText}
            taskWarningText={taskWarningText}
          />
        )
      } else {
        content = (
          <FbUnfriendBody
            accessLevel={accessLevel}
            handleShowUpsell={onClickUpsell}
            languageLocked={!isLanguageSupported}
            platformUserId={platformUserId}
            progress={progress}
          />
        )
      }
      break
    default:
      content = (
        <ManualBody
          isAnimatingCardStateTransition={isAnimatingCardStateTransition}
          customActionDescription={customActionDescription}
          defaultBodyTitle={bodyTitle}
          recommendationState={recommendationState}
          onClickAction={onClickMarkAsDone}
          onClickSkip={onClickSkip}
          onClickUndo={onClickUndoManual}
          onClickUndoSkip={onClickUndoMarkAsSkipped}
          steps={steps}
          locked={false}
        />
      )
      break
  }

  const [showCopyToast, setShowCopyToast] = useState(false)
  const handleCopyDeeplink = useCallback(() => {
    if (deeplinkShareUrl) {
      navigator.clipboard.writeText(deeplinkShareUrl)
    }
    setShowCopyToast(true)
    setTimeout(() => {
      setShowCopyToast(false)
    }, 2000)
  }, [deeplinkShareUrl])

  const logEventProps = {
    component: 'RecommendationCard',
    cardType: recommendationCardType,
    concernTags,
    displayPosition,
    hasAutomatedGather,
    isLessRelevant,
    isNotGathered: !initialState,
    platform,
    recommendationKey,
    recommendationState,
    settingsSelected: settingsSelected
      ? Object.keys(settingsSelected).filter(
          (setting) => settingsSelected[setting],
        )
      : [],
  }

  return (
    <LogContextProvider {...logEventProps}>
      <ImpressionLogger
        eventName="recommendation:view"
        eventProps={logEventProps}
      >
        <section
          aria-labelledby={`${recommendationKey}-title`}
          className={clsx(
            'RecommendationCard',
            recommendationCardType ===
              RecommendationCardType.FACEBOOK_PHOTO_UNTAGGING &&
              'facebook-photo-untagging',
          )}
        >
          {showCopyToast && <Toast color="green" text="Link copied" />}
          {showConfetti && <Confetti className="confetti" />}
          <Header
            accessLevel={accessLevel}
            concernTags={concernTags}
            hasAutomatedGather={hasAutomatedGather}
            isTask={isTask}
            isLanguageSupported={isLanguageSupported}
            isLessRelevant={isLessRelevant}
            isPhoto={isPhoto}
            locked={!isLanguageSupported}
            onReScanClick={onClickCheckSettings}
            recommendationCardType={recommendationCardType}
            recommendationState={recommendationState}
          />
          {recommendationCardType === RecommendationCardType.TASK &&
            platform === PLATFORM.TWITTER &&
            initialState?.result && (
              <Banner
                title={initialState.result.title}
                detail={initialState.result.detail}
                footerText={initialState.result.helper?.text}
                footerLink={initialState.result.helper?.link}
                onClickClose={() => {
                  const updatedInitialState = { ...initialState }
                  delete updatedInitialState.result

                  state.updateRecommendation(
                    platform,
                    platformUserId,
                    recommendationKey as RecommendationKeyEnum,
                    { initialState: updatedInitialState },
                    { doNotUpdateLastUpdatedTimestamp: true },
                  )
                }}
              />
            )}
          <div className={clsx('recommendation-title-wrapper', addClickable)}>
            {subheader}
            <div className="recommendation-title">
              <h3
                className={clsx(
                  'title',
                  'heading3',
                  'bold',
                  'black',
                  showGlow && 'glow',
                )}
              >
                <div className="title-text">{recommendationTitle}</div>
                {deeplinkShareUrl && (
                  <InteractiveElement
                    className="share-url"
                    aria-label={`Copy ${recommendationKey} deeplink`}
                    role="button"
                  >
                    <CopyToClipboard
                      onCopy={handleCopyDeeplink}
                      text={deeplinkShareUrl}
                    >
                      <img
                        alt="copy link"
                        srcSet={LinkIcon}
                        className="link-icon"
                      />
                    </CopyToClipboard>
                  </InteractiveElement>
                )}
              </h3>
              {explanationText && !showExplanation ? (
                <InteractiveElement
                  aria-label="Show Explanation"
                  aria-expanded={showExplanation}
                  className="toggle-explanation-button"
                  onClick={toggleShowExplanation}
                >
                  <SectionExpandIcon
                    aria-hidden
                    className="toggle-explanation-icon"
                  />
                </InteractiveElement>
              ) : (
                <InteractiveElement
                  aria-label="Hide Explanation"
                  aria-expanded={showExplanation}
                  className="toggle-explanation-button"
                  onClick={toggleShowExplanation}
                >
                  <SectionCollapseIcon
                    aria-hidden
                    className="toggle-explanation-icon"
                  />
                </InteractiveElement>
              )}
            </div>
            <div
              className={`recommendation-explanation-text ${addlExplanationTextClassName}`}
            >
              <span className="body2">{explanationText}</span>
            </div>
          </div>
          {content}
        </section>
      </ImpressionLogger>
    </LogContextProvider>
  )
}
