import {
  TwitterRecommendationKey,
  RecommendationTaskType,
} from '../../utils/enums'
import metadata from '../metadata/twitter.yaml'
import { parseMetadataRegistry } from '../recommendationMetadataParsing'
import { parseSpec } from '../recommendationSpecParsing'
import { Recommendation } from '../recommendationTypes'
import { RecommendationRegistryApi } from '../registryTypes'
import specs from '../specs/twitter.yaml'

const TWITTER_RECOMMENDATION_KEYS = [
  TwitterRecommendationKey.TWITTER_2FA,
  TwitterRecommendationKey.TWITTER_AI_OPT_OUT,
  TwitterRecommendationKey.TWITTER_AUDIO_VIDEO_CALLS,
  TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS,
  TwitterRecommendationKey.TWITTER_CONTACTS,
  TwitterRecommendationKey.TWITTER_REMOVE_LIKES,
  TwitterRecommendationKey.TWITTER_DELETE_POSTS_BEFORE,
  TwitterRecommendationKey.TWITTER_LEAVE_DIRECT_MESSAGES,
  TwitterRecommendationKey.TWITTER_DISCOVERABILITY,
  TwitterRecommendationKey.TWITTER_LOCATION_ACCESS,
  TwitterRecommendationKey.TWITTER_LOCATION_PROFILE,
  TwitterRecommendationKey.TWITTER_LOCATION_TWEETS,
  TwitterRecommendationKey.TWITTER_MUTED_NOTIFICATIONS,
  TwitterRecommendationKey.TWITTER_SECURITY,
  TwitterRecommendationKey.TWITTER_SESSIONS,
]

const TWITTER_SETUP_EXCLUSION_KEYS = [
  TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS,
]

const TWITTER_RECOMMENDATIONS_REGISTRY: Record<
  TwitterRecommendationKey,
  Recommendation
> = {
  [TwitterRecommendationKey.TWITTER_MUTED_NOTIFICATIONS]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_MUTED_NOTIFICATIONS]),
    key: TwitterRecommendationKey.TWITTER_MUTED_NOTIFICATIONS,
  },
  [TwitterRecommendationKey.TWITTER_LOCATION_ACCESS]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_LOCATION_ACCESS]),
    key: TwitterRecommendationKey.TWITTER_LOCATION_ACCESS,
  },
  [TwitterRecommendationKey.TWITTER_LOCATION_PROFILE]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_LOCATION_PROFILE]),
    key: TwitterRecommendationKey.TWITTER_LOCATION_PROFILE,
  },
  [TwitterRecommendationKey.TWITTER_LOCATION_TWEETS]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_LOCATION_TWEETS]),
    key: TwitterRecommendationKey.TWITTER_LOCATION_TWEETS,
  },
  [TwitterRecommendationKey.TWITTER_DISCOVERABILITY]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_DISCOVERABILITY]),
    key: TwitterRecommendationKey.TWITTER_DISCOVERABILITY,
  },
  [TwitterRecommendationKey.TWITTER_CONTACTS]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_CONTACTS]),
    key: TwitterRecommendationKey.TWITTER_CONTACTS,
  },
  [TwitterRecommendationKey.TWITTER_SECURITY]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_SECURITY]),
    key: TwitterRecommendationKey.TWITTER_SECURITY,
  },
  [TwitterRecommendationKey.TWITTER_2FA]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_2FA]),
    hasAutomatedGather: true,
    isAutomated: false,
    key: TwitterRecommendationKey.TWITTER_2FA,
  },
  [TwitterRecommendationKey.TWITTER_AUDIO_VIDEO_CALLS]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_AUDIO_VIDEO_CALLS]),
    key: TwitterRecommendationKey.TWITTER_AUDIO_VIDEO_CALLS,
  },
  [TwitterRecommendationKey.TWITTER_SESSIONS]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_SESSIONS]),
    key: TwitterRecommendationKey.TWITTER_SESSIONS,
  },
  [TwitterRecommendationKey.TWITTER_DELETE_POSTS_BEFORE]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_DELETE_POSTS_BEFORE]),
    hasAutomatedGather: true,
    key: TwitterRecommendationKey.TWITTER_DELETE_POSTS_BEFORE,
  },
  [TwitterRecommendationKey.TWITTER_AI_OPT_OUT]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_AI_OPT_OUT]),
    key: TwitterRecommendationKey.TWITTER_AI_OPT_OUT,
  },
  [TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS]),
    key: TwitterRecommendationKey.TWITTER_BLOCK_REPOSTERS,
  },
  [TwitterRecommendationKey.TWITTER_LEAVE_DIRECT_MESSAGES]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_LEAVE_DIRECT_MESSAGES]),
    key: TwitterRecommendationKey.TWITTER_LEAVE_DIRECT_MESSAGES,
  },
  [TwitterRecommendationKey.TWITTER_REMOVE_LIKES]: {
    ...parseSpec(specs[TwitterRecommendationKey.TWITTER_REMOVE_LIKES]),
    key: TwitterRecommendationKey.TWITTER_REMOVE_LIKES,
  },
}

export const twitterRegistry: RecommendationRegistryApi = {
  getRecommendationKeys: () => TWITTER_RECOMMENDATION_KEYS,
  getSetupRecommendationKeys: () =>
    TWITTER_RECOMMENDATION_KEYS.filter(
      (key) => !TWITTER_SETUP_EXCLUSION_KEYS.includes(key),
    ),
  getContentTaskKeys: () =>
    TWITTER_RECOMMENDATION_KEYS.filter(
      (key) =>
        TWITTER_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.CONTENT,
    ),
  getExperienceTaskKeys: () =>
    TWITTER_RECOMMENDATION_KEYS.filter(
      (key) =>
        TWITTER_RECOMMENDATIONS_REGISTRY[key].taskType ===
        RecommendationTaskType.EXPERIENCE,
    ),
  getRecommendationsRegistry: () => TWITTER_RECOMMENDATIONS_REGISTRY,
  getRecommendationsMetadataRegistry: () => parseMetadataRegistry(metadata),
}
